import { isNil } from 'lodash-es';
import { Observable, combineLatest, defer } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import { createEffect, ofType } from '@ngrx/effects';

import {
	paginateArray, sortArray, RecordsPage, IPageQueryParams, ISortQueryParams
} from '@bp/shared/models/common';
import { IdentifiableEntity, IEntitiesApiService } from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

import { filterPresent } from '@bp/frontend/rxjs';
import { apiResult } from '@bp/frontend/models/common';

import { EntitiesInMemoryPagedListFacade } from './entities-in-memory-paged-list.facade';
import { EntitiesListBaseEffects } from './entities-list-base.effects';
import { EntitiesInMemoryPagedListState } from './compose-entities-list-reducer';

export abstract class EntitiesInMemoryPagedListEffects<
	TEntity extends IdentifiableEntity,
	TState extends EntitiesInMemoryPagedListState<TEntity>,
	TLoadQueryParams extends (IPageQueryParams & ISortQueryParams),
	TEntitiesFacade extends EntitiesInMemoryPagedListFacade<TEntity, TState, TLoadQueryParams>
>
	extends EntitiesListBaseEffects<
		TEntity,
		TState,
		TLoadQueryParams,
		TEntitiesFacade,
		IEntitiesApiService<TEntity, TLoadQueryParams>
	> {

	loadOnNavigationToRouteComponent$ = createEffect(() => defer(() => this.routeComponentActivation$
		.pipe(map(this.actions.loadAll))));

	getAllRecordsPage$ = createEffect(() => this._actions$.pipe(
		ofType(this.actions.loadAll, this.actions.load, this.actions.refresh),
		switchMap(() => this._loadAll()
			.pipe(apiResult(this.actions.api.loadAllSuccess, this.actions.api.loadAllFailure))),
	));

	filterInMemory$ = createEffect(() => combineLatest([
		this._entitiesFacade.all$,
		this.apiQueryParamsWithoutPage$.pipe(startWith(null)),
	])
		.pipe(map(([ all, queryParams ]) => {
			all = all ? [ ...all ] : [];

			return this.actions.filteredInMemory({
				filtered: this._sortFilteredRecords(
					queryParams ? this._filterRecordsInMemoryOnQueryParamsChange(all, queryParams) : all,
					queryParams,
				),
			});
		})));

	pageFilter$ = createEffect(() => combineLatest([
		this._entitiesFacade.filteredInMemory$.pipe(filterPresent),
		this.apiQueryParamsWithPage$.pipe(
			distinctUntilChanged((a, b) => a.limit === b.limit && a.page === b.page),
			startWith(this._entitiesFacade.queryParamsFactory({})),
		),
	])
		.pipe(map(([ filteredRecords, query ]) => {
			const currentPage = query.page ? Number.parseInt(query.page) : 1;
			const hasNextPage = filteredRecords.length > query.limit * currentPage;

			return this.actions.filteredRecordsPage({
				recordsPage: new RecordsPage({
					firstPage: currentPage === 1,
					nextPageCursor: hasNextPage ? (currentPage + 1).toString() : null,
					records: paginateArray(filteredRecords, query),
				}),
			});
		})));

	protected abstract _loadQueryParamsFactory: (dto?: Partial<TLoadQueryParams>) => TLoadQueryParams;

	protected _defaultSortParams: ISortQueryParams<NonFunctionPropertyNames<TEntity>> | null = null;

	protected abstract _filterRecordsInMemoryOnQueryParamsChange(
		records: TEntity[],
		queryParams: TLoadQueryParams
	): TEntity[];

	protected _setDefaultSortParams(sortParams: ISortQueryParams<NonFunctionPropertyNames<TEntity>>): void {
		this._defaultSortParams = sortParams;
	}

	protected _sortFilteredRecords(
		records: TEntity[],
		sortParams?: ISortQueryParams | null,
	): TEntity[] {
		const sortingParams = sortParams?.sortField
			? sortParams
			: <ISortQueryParams> this._defaultSortParams;

		if (isNil(sortingParams))
			return records;

		return sortArray(records, sortingParams);
	}

	protected _loadAll(): Observable<RecordsPage<TEntity>> {
		return this._apiService
			.getRecordsPage(this._loadQueryParamsFactory(<TLoadQueryParams>{ limit: 999_999 }));
	}

}
